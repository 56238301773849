<template>
  <div class="page-container">
    <router-link to="/">
      <img class="logo" src="@/assets/images/esprezzo-logo.png" />
    </router-link>
    <div ref="container" class="container">
      <div class="alert error">
        {{ errorMessage }}
      </div>
      <router-link to="/dashboard" class="button" v-if="$store.getters['user/isAuthenticated']">Go to Dashboard</router-link>
      <router-link to="/login" class="button" v-else>Go to Login</router-link>
    </div>
  </div>
</template>

<script>

  export default {
    computed: {
      errorMessage() {
        return (
          this.$store.state.api.dispatchAPIErrors[this.$route.params.errorCode]
          || this.$store.state.api.dispatchAPIErrors.unknown_error
        )
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/logged-out-page.styl'

  .container
    @apply flex
    @apply flex-col
    @apply items-center

    +breakpoint(sm)
      @apply py-12
      @apply px-12

  .button
    @apply mt-6

</style>
